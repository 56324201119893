<script setup lang='ts'>

import { useForm } from '@tanstack/vue-form'

type Centre = Infer<'auth.readCentres'>[number]
type Exams = Infer<'auth.readExams'>

const runtimeConfig = useRuntimeConfig()
const locale = useLocale()
const { $cs, $getQueryKey } = useNuxtApp()

const dayjs = useDayjs()
const examStore = useExamStore()
const loginStore = useLoginStore()
const toastStore = useToastStore()
const router = useRouter()

const { mutate: login, error: loginError } = $cs.auth.createJwt.useMutation()

const formRef = ref<HTMLFormElement>()

const centres = ref<Array<Centre>>([])
const exams = ref<Exams>()

const date = ref<string>(dayjs().format('YYYY-MM-DD'))
const centre = ref<string>()
const subject = ref<string>()
const paper = ref<number>()
const pendingAuth = ref(false)
const promptReplacement = ref(false)

const Form = useForm({
    defaultValues: {
        epNumber: '',
        password: ''
    },
    onSubmit({ value: { epNumber, password } }) {
        if (loginStore.getCookiesToken()) {
            return router.go(0)
        }

        sendLoginRequest(epNumber, password, false)
    }
})

watch(centre, () => {
    if (!centre.value) {
        return
    }

    pendingAuth.value = true

    $cs.auth.readExams
        .query({
            date: date.value,
            centre: centre.value
        })
        .then(response => {
            exams.value = response
            pendingAuth.value = false

            if (subject.value && !response[subject.value]) {
                subject.value = undefined
            }

            if (subject.value && paper.value !== undefined && !response[subject.value]?.papers[paper.value]) {
                paper.value = undefined
            }
        })
})

onMounted(() => fetchCentres(date.value))

function fetchCentres(_date: string) {
    pendingAuth.value = true

    $cs.auth.readCentres.query(_date).then(response => {
        centres.value = response
        centre.value = undefined
        pendingAuth.value = false
    })
}

async function sendLoginRequest(epNumber: string, password: string, override?: boolean) {
    promptReplacement.value = false

    if (!(exams.value && subject.value && paper.value && epNumber && password)) {
        return
    }

    pendingAuth.value = true

    const response = await login({
        examSessionId: exams.value[subject.value][`${paper.value}`].exam_session_id,
        epNumber,
        password,
        override
    })

    if (!response) {
        pendingAuth.value = false

        return loginError.value && toastStore.push({
            key: loginError.value.message,
            type: 'warn',
            message: { key: `toast.error.${loginError.value.message}` }
        })
    }

    if (response.shouldPrompt) {
        return (promptReplacement.value = !(pendingAuth.value = false))
    }

    loginStore.accessToken = response.accessToken
    loginStore.validatedAt = new Date().toString()

    examStore.registerExam(response)
    toastStore.$reset()

    await refreshNuxtData($getQueryKey($cs.shared.readJwt, undefined))

    if (response.formOfExam.includes('MB')) {
        await navigateTo('/messageBoardOnlyExam')
    }
    else if (getIsSecondarySubject(response)) {
        await navigateTo('/attendance')
    }
    else if (isAfterToday(response.examDateUtc)) {
        await navigateTo('/assignment')
    }
    else {
        await navigateTo('/assignee')
    }

    toastStore.push({
        key: 'login_success',
        type: 'info',
        message: { key: 'toast.success.login' }
    })
}

</script>

<template>
    <form
        ref='formRef'
        class='space-y-5 px-12 py-6'
        @submit='e => {
            e.preventDefault()
            e.stopPropagation()
            void Form.handleSubmit()
        }'
    >
        <LoginFormRow :label='$t("auth.examination_date")'>
            <StyledInput
                name='exam-date'
                type='date'
                :value='date'
                :attributes='{ min: $dayjs().format("YYYY-MM-DD") }'
                @input='fetchCentres(date = $event)'
            />
        </LoginFormRow>

        <LoginFormRow :label='$t("auth.examination_centre")'>
            <StyledCombobox
                v-model='centre'
                name='centre-code'
                field='value'
                show-options-on='focus'
                :display-field='locale === "en" ? "name_eng" : "name_chi"'
                :options='centres'
            />
        </LoginFormRow>

        <LoginFormRow :label='$t("auth.subject")'>
            <HeadlessListbox
                v-model='subject'
                :disabled='!centre || pendingAuth'
            >
                <div class='relative'>
                    <StyledListboxButton
                        id='subject-selector'
                        class='h-10 border-2'
                        :class='{ "opacity-50": !centre || pendingAuth }'
                    >
                        <span>{{ (subject && exams?.[subject][locale === "en" ? "name_eng" : "name_chi"]) || $t('general_terms.please_select') }}</span>
                    </StyledListboxButton>

                    <StyledListboxOptions v-if='exams'>
                        <StyledListboxOption
                            v-for='_subject in exams'
                            :id='`subject-option-${_subject.value}`'
                            :key='_subject.value'
                            :value='_subject.value'
                            :display-text='_subject[locale === "en" ? "name_eng" : "name_chi"]'
                        />
                    </StyledListboxOptions>
                </div>
            </HeadlessListbox>
        </LoginFormRow>

        <LoginFormRow :label='$t("auth.paper")'>
            <HeadlessListbox
                v-model='paper'
                :disabled='!subject || pendingAuth'
            >
                <div class='relative'>
                    <StyledListboxButton
                        id='paper-selector'
                        class='h-10 border-2'
                        :class='{ "opacity-50": !subject || pendingAuth }'
                    >
                        <span>{{ paper || $t('general_terms.please_select') }}</span>
                    </StyledListboxButton>

                    <StyledListboxOptions>
                        <StyledListboxOption
                            v-for='_paper in subject && exams?.[subject]?.papers || []'
                            :id='`paper-option-${_paper.value}`'
                            :key='_paper.value'
                            :value='_paper.value'
                            :display-text='_paper[locale === "en" ? "name_eng" : "name_chi"]'
                        />
                    </StyledListboxOptions>
                </div>
            </HeadlessListbox>
        </LoginFormRow>

        <Form.Field v-slot='{ field: epField }' name='epNumber'>
            <LoginFormRow :label='$t("auth.ep_number")'>
                <StyledCombobox
                    v-if='runtimeConfig.public.VITE_BUILD_VERSION === "DEV"'
                    name='ep-number'
                    field='ep_no'
                    display-field='ep_no'
                    show-options-on='focus'
                    :options='exams && subject && paper && exams[subject]?.[`${paper}`]?.supervisors || []'
                    :disabled='!paper || pendingAuth'
                    :tooltip='[$t("auth.ep_no_hint_1"), $t("auth.ep_no_hint_2")]'
                    @update='epField.handleChange($event)'
                />
                <StyledInput
                    v-else
                    name='ep-number'
                    disabled-style='outline'
                    :tooltip='[$t("auth.ep_no_hint_1"), $t("auth.ep_no_hint_2")]'
                    :value='epField.state.value'
                    :attributes='{
                        minlength: 7,
                        maxlength: 9,
                        disabled: !paper || pendingAuth
                    }'
                    @input='exams && subject && paper && epField.handleChange($event)'
                />
            </LoginFormRow>

            <Form.Field v-slot='{ field: passwordField }' name='password'>
                <LoginFormRow :label='$t("auth.password")'>
                    <input
                        id='password-input'
                        class='w-full appearance-none rounded border-2 px-3 py-2 leading-tight text-gray-700 focus:border-blue-700 focus:outline-none'
                        type='password'
                        name='password'
                        autocomplete='off'
                        :disabled='!epField.state.value || pendingAuth'
                        @keydown='({ code }) => code === "Enter" && formRef?.reportValidity() && Form.handleSubmit()'
                        @input='passwordField.handleChange(($event.target as HTMLInputElement).value)'
                    >
                </LoginFormRow>

                <div class='px-3 pb-1 pt-2 text-center'>
                    <GreenSolidButton
                        id='login-button'
                        class='h-11 w-auto px-10 py-2 text-lg font-bold tracking-wide'
                        type='submit'
                        :disabled='!passwordField.state.value || pendingAuth'
                    >
                        <span>{{ $t('auth.login') }}</span>
                    </GreenSolidButton>
                </div>
            </Form.Field>
        </Form.Field>
    </form>

    <ConfirmationDialogue
        static
        cancel-button-id='cancel-replacement-button'
        confirm-button-id='confirm-replacement-button'
        :show='promptReplacement'
        :header='$t("general_terms.reminder")'
        :message='$t("toast.info.if_you_continue_logging_in")'
        @cancel='promptReplacement = false'
        @confirm='sendLoginRequest(Form.getFieldValue("epNumber"), Form.getFieldValue("password"), true)'
    />
</template>
