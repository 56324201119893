<template>
    <div class='m-auto min-w-[640px] max-w-fit overflow-visible bg-white shadow-xl md:rounded-xl'>
        <div class='h-16 w-full bg-blue-900 text-xl font-bold text-white md:rounded-t-xl'>
            <span class='flex items-center gap-4 p-5'>
                <LoginIcon />
                <span class='tracking-wide'>{{ $t('auth.login') }}</span>
            </span>
        </div>

        <LoginForm />
    </div>
</template>
